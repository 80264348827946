// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import { EntityIcon } from '../../../Icon'

import styles from './LinkedRequests.module.scss'

type Props = {
  requests: Array<Object>,
}

const LinkedRequests = (props: Props): Node => {
  const { requests } = props

  const { t } = useTranslation('Common')

  if (!requests || !requests.length) {
    return null
  }

  return (
    <>
      {requests.map(request => (
        <div key={request.id} className={styles.request}>
          <span className='bar__cell-value'>
            <EntityIcon id='request' />
            <Link to={`/request/${request.id}`}>
              {t('NumberSymbol')} {request.request_no}
            </Link>
            <span>&nbsp;{request.title}</span>
          </span>
        </div>
      ))}
    </>
  )
}

export default LinkedRequests
