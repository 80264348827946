// @flow

import { lazy } from 'react'

export const about = lazy(() => import('../../../static/icons/about.svg'))
export const add = lazy(() => import('../../../static/icons/add.svg'))
export const address = lazy(() => import('../../../static/icons/address.svg'))
export const apartment = lazy(() =>
  import('../../../static/icons/apartment.svg')
)
export const automate = lazy(() => import('../../../static/icons/automate.svg'))
export const bell = lazy(() => import('../../../static/icons/bell.svg'))
export const bin = lazy(() => import('../../../static/icons/bin.svg'))
export const building = lazy(() => import('../../../static/icons/building.svg'))
export const chat = lazy(() => import('../../../static/icons/chat.svg'))
export const check = lazy(() => import('../../../static/icons/check.svg'))
export const checklist = lazy(() =>
  import('../../../static/icons/checklist.svg')
)
export const company = lazy(() => import('../../../static/icons/company.svg'))
export const constructor = lazy(() =>
  import('../../../static/icons/constructor.svg')
)
export const cross = lazy(() => import('../../../static/icons/cross.svg'))
export const addEnv = lazy(() => import('../../../static/icons/addEnv.svg'))
export const connectEnv = lazy(() =>
  import('../../../static/icons/connectEnv.svg')
)
export const deadline = lazy(() => import('../../../static/icons/deadline.svg'))
export const email = lazy(() => import('../../../static/icons/email.svg'))
export const eye = lazy(() => import('../../../static/icons/eye.svg'))
export const faq = lazy(() => import('../../../static/icons/faq.svg'))
export const favorite = lazy(() => import('../../../static/icons/favorite.svg'))
export const folder = lazy(() => import('../../../static/icons/folder.svg'))
export const folderWhite = lazy(() =>
  import('../../../static/icons/folder-white.svg')
)
export const forward = lazy(() => import('../../../static/icons/forward.svg'))
export const friends = lazy(() => import('../../../static/icons/friends.svg'))
export const home = lazy(() => import('../../../static/icons/home.svg'))
export const info = lazy(() => import('../../../static/icons/info.svg'))
export const integrations = lazy(() =>
  import('../../../static/icons/integrations.svg')
)
export const label = lazy(() => import('../../../static/icons/label.svg'))
export const like = lazy(() => import('../../../static/icons/like.svg'))
export const lock = lazy(() => import('../../../static/icons/lock.svg'))
export const marketplace = lazy(() =>
  import('../../../static/icons/marketplace.svg')
)
export const newStar = lazy(() => import('../../../static/icons/new-star.svg'))
export const objects = lazy(() => import('../../../static/icons/objects.svg'))
export const offer = lazy(() => import('../../../static/icons/offer.svg'))
export const order = lazy(() => import('../../../static/icons/order.svg'))
export const pencil = lazy(() => import('../../../static/icons/pencil.svg'))
export const people = lazy(() => import('../../../static/icons/people.svg'))
export const phone = lazy(() => import('../../../static/icons/phone.svg'))
export const pin = lazy(() => import('../../../static/icons/pin.svg'))
export const play = lazy(() => import('../../../static/icons/play.svg'))
export const plus = lazy(() => import('../../../static/icons/plus.svg'))
export const plusLarge = lazy(() =>
  import('../../../static/icons/plus-large.svg')
)
export const close = lazy(() => import('../../../static/icons/close.svg'))
export const post = lazy(() => import('../../../static/icons/post.svg'))
export const printer = lazy(() => import('../../../static/icons/printer.svg'))
export const provider = lazy(() => import('../../../static/icons/provider.svg'))
export const purpose = lazy(() => import('../../../static/icons/purpose.svg'))
export const removeItem = lazy(() => import('../../../static/icons/remove.svg'))
export const request = lazy(() => import('../../../static/icons/request.svg'))
export const reply = lazy(() => import('../../../static/icons/reply.svg'))
export const replyAll = lazy(() =>
  import('../../../static/icons/reply-all.svg')
)
export const restore = lazy(() => import('../../../static/icons/restore.svg'))
export const review = lazy(() => import('../../../static/icons/review.svg'))
export const settings = lazy(() => import('../../../static/icons/settings.svg'))
export const starFilled = lazy(() =>
  import('../../../static/icons/star-filled.svg')
)
export const status = lazy(() => import('../../../static/icons/status.svg'))
export const upload = lazy(() => import('../../../static/icons/upload.svg'))
export const updates = lazy(() => import('../../../static/icons/updates.svg'))
export const user = lazy(() => import('../../../static/icons/user.svg'))
export const website = lazy(() => import('../../../static/icons/website.svg'))
export const messageRead = lazy(() =>
  import('../../../static/icons/message-read.svg')
)
export const messageUnread = lazy(() =>
  import('../../../static/icons/message-unread.svg')
)
export const emailRead = lazy(() =>
  import('../../../static/icons/email-read.svg')
)
export const emailUnread = lazy(() =>
  import('../../../static/icons/email-unread.svg')
)
export const requestRead = lazy(() =>
  import('../../../static/icons/request-read.svg')
)
export const requestUnread = lazy(() =>
  import('../../../static/icons/request-unread.svg')
)
export const realData = lazy(() =>
  import('../../../static/icons/real-data.svg')
)
export const magnifier = lazy(() =>
  import('../../../static/icons/magnifier.svg')
)
export const danger = lazy(() => import('../../../static/icons/danger.svg'))
export const integration = lazy(() =>
  import('../../../static/icons/integration.svg')
)
export const move = lazy(() => import('../../../static/icons/move.svg'))
export const clone = lazy(() => import('../../../static/icons/clone.svg'))
export const variable = lazy(() => import('../../../static/icons/variable.svg'))
export const templates = lazy(() =>
  import('../../../static/icons/templates.svg')
)
export const back = lazy(() => import('../../../static/icons/back.svg'))
export const planet = lazy(() => import('../../../static/icons/planet.svg'))
export const requestProvider = lazy(() =>
  import('../../../static/icons/request-provider.svg')
)
export const providerNotify = lazy(() =>
  import('../../../static/icons/provider-notify.svg')
)
export const attach = lazy(() => import('../../../static/icons/attach.svg'))

export const aCar = lazy(() =>
  import('../../../static/icons/activities/car.svg')
)
export const aLocation = lazy(() =>
  import('../../../static/icons/activities/location.svg')
)
export const aEmail = lazy(() =>
  import('../../../static/icons/activities/email.svg')
)
export const aVacation = lazy(() =>
  import('../../../static/icons/activities/vacation.svg')
)
export const activity = lazy(() =>
  import('../../../static/icons/activities/activity.svg')
)
export const hide = lazy(() =>
  import('../../../static/icons/activities/hide.svg')
)
export const manager = lazy(() =>
  import('../../../static/icons/activities/manager.svg')
)
export const activityPin = lazy(() =>
  import('../../../static/icons/activities/activity-pin.svg')
)
export const superIcon = lazy(() =>
  import('../../../static/icons/activities/super.svg')
)
export const showEye = lazy(() =>
  import('../../../static/icons/activities/show-eye.svg')
)
export const hideEye = lazy(() =>
  import('../../../static/icons/activities/hide-eye.svg')
)
export const addActivity = lazy(() =>
  import('../../../static/icons/activities/add-activity.svg')
)
export const budgetPlus = lazy(() =>
  import('../../../static/icons/budget/plus.svg')
)
export const pipeline = lazy(() => import('../../../static/icons/pipeline.svg'))
export const budget = lazy(() => import('../../../static/icons/budget.svg'))
export const openEnv = lazy(() => import('../../../static/icons/open-env.svg'))
export const openErrorEnv = lazy(() =>
  import('../../../static/icons/open-error-env.svg')
)
export const closedErrorEnv = lazy(() =>
  import('../../../static/icons/closed-error-env.svg')
)
export const closedEnv = lazy(() =>
  import('../../../static/icons/closed-env.svg')
)
export const link = lazy(() => import('../../../static/icons/link.svg'))
export const error = lazy(() => import('../../../static/icons/error.svg'))
export const repeat = lazy(() => import('../../../static/icons/repeat.svg'))
export const remove = lazy(() =>
  import('../../../static/icons/icon-delete.svg')
)
export const corporate = lazy(() =>
  import('../../../static/icons/corporate.svg')
)
export const threadActivity = lazy(() =>
  import('../../../static/icons/activities/thread-activity.svg')
)
export const ActivityMenu = lazy(() =>
  import('../../../static/icons/activities/activity-menu.svg')
)
export const emailActivity = lazy(() =>
  import('../../../static/icons/activities/email-activity.svg')
)

export const privateAcc = lazy(() =>
  import('../../../static/icons/private-acc.svg')
)
export const corporateAcc = lazy(() =>
  import('../../../static/icons/corporate-acc.svg')
)
export const onDelete = lazy(() => import('../../../static/icons/delete.svg'))
export const arrowRight = lazy(() =>
  import('../../../static/icons/arrow-right.svg')
)
export const gmail = lazy(() => import('../../../static/icons/gmail.svg'))
export const mOffice = lazy(() =>
  import('../../../static/icons/microsoft-office.svg')
)
export const mExchange = lazy(() =>
  import('../../../static/icons/microsoft-exchange.svg')
)
export const outlook = lazy(() => import('../../../static/icons/outlook.svg'))
export const googleDrive = lazy(() =>
  import('../../../static/icons/google-drive-template.svg')
)
export const attachFile = lazy(() =>
  import('../../../static/icons/attach-file.svg')
)

export const openSection = lazy(() =>
  import('../../../static/icons/openSection.svg')
)

export const closedSection = lazy(() =>
  import('../../../static/icons/closeSection.svg')
)

export const download = lazy(() => import('../../../static/icons/download.svg'))
export const loader = lazy(() => import('../../../static/icons/loader.svg'))
export const closeBig = lazy(() =>
  import('../../../static/icons/close-big.svg')
)

export const expend = lazy(() => import('../../../static/icons/expand.svg'))

export const groups = lazy(() => import('../../../static/icons/groups.svg'))

export const relatedSr = lazy(() =>
  import('../../../static/icons/related-sr.svg')
)
