// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'

import Button from '../Button'
import Option from '../RequestSettings/Option'
import {
  getOption,
  isDefaultOption,
} from '../RequestSettings/RequestSettings.utils'
import { SETTINGS_CORPORATE_SAVE_CONFIG } from '../SettingsCorporate/SettingsCorporate.actionTypes'
import NewSelectSimple from '../NewSelectSimple'

const TableConfigSettings = props => {
  const {
    ukConfig: { table_config_preset: tableConfigPreset, display_orag_claster },
  } = props

  const { t } = useTranslation('Settings')
  const dispatch = useDispatch()

  const getOptions = () => {
    let options = [
      { value: 'basic', label: t('TableConfigBasic') },
      { value: 'advanced', label: t('TableConfigAdvanced') },
      { value: 'all', label: t('TableConfigAll') },
    ]

    if (display_orag_claster) {
      options.push({ value: 'orag', label: t('TableConfigOrag') })
    }
  }

  const defaultOption = getOption(getOptions(), tableConfigPreset)

  const [tableConfigPresetOption, setTableConfigPresetOption] =
    useState(defaultOption)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const { value: prevOption } = tableConfigPresetOption

    if (tableConfigPreset !== prevOption) {
      setTableConfigPresetOption(getOption(getOptions(), tableConfigPreset))
    }

    setLoading(false)
  }, [tableConfigPreset])

  const handleSave = () => {
    setLoading(true)

    dispatch({
      type: SETTINGS_CORPORATE_SAVE_CONFIG,
      params: {
        table_config_preset: tableConfigPresetOption.value,
      },
    })
  }

  const handleCancel = () => {
    setTableConfigPresetOption(defaultOption)
  }

  const sectionClass = classnames(
    'settings-personal unit unit--default settings-regional',
    {
      'working-overlay': loading,
    }
  )

  const submitSectionClass = classnames('settings-personal__submit', {
    'working-overlay': loading,
  })

  return (
    <div>
      <section className={sectionClass}>
        <h2 className='unit__title'>{t('TableConfigTitle')}</h2>

        <Option label={t('TableConfigRedesign')}>
          <NewSelectSimple
            name='table-config-preset'
            value={tableConfigPresetOption}
            options={getOptions()}
            onChange={setTableConfigPresetOption}
          />
        </Option>
        <div className={submitSectionClass}>
          <Button.Save
            type='button'
            disabled={
              loading || isDefaultOption(defaultOption, tableConfigPresetOption)
            }
            onClick={handleSave}
          >
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel
            type='button'
            disabled={
              loading || isDefaultOption(defaultOption, tableConfigPresetOption)
            }
            onClick={handleCancel}
          >
            {t('Common:Cancel')}
          </Button.Cancel>
        </div>
      </section>
    </div>
  )
}

export default TableConfigSettings
